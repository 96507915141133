var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c(_vm.currentComponent, {
    tag: "component",
    attrs: {
      "pengembalian": _vm.item
    },
    on: {
      "view": _vm.changeComponent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }