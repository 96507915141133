var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "vertical": _vm.isVerticalDevice,
      "pills": ""
    }
  }, [_c('b-tab', {
    ref: "stok-tab",
    attrs: {
      "title": "Stok Mobil",
      "active": _vm.stokTab
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.stokTab = $event;
      }
    }
  }, [_c('stok-mobil', {
    on: {
      "submitRetur": _vm.afterSubmitRetur
    }
  })], 1), _c('b-tab', {
    ref: "retur-tab",
    attrs: {
      "title": "Stok Retur",
      "active": _vm.returTab
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.returTab = $event;
      }
    }
  }, [_c('retur-konsumen', {
    attrs: {
      "active": _vm.returTab
    }
  })], 1), _c('b-tab', {
    ref: "pengembalian-tab",
    attrs: {
      "title": "Data Pengembaliaan",
      "active": _vm.pengembalianTab
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.pengembalianTab = $event;
      }
    }
  }, [_c('pengembalian', {
    attrs: {
      "active": _vm.pengembalianTab
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }