<template>
  <b-overlay :show="loading">
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-button
            v-if="isSales"
            variant="primary"
            @click.prevent="returnBarang"
            :disabled="checkedBarangs.length < 1"
          >
            <feather-icon icon="CornerUpRightIcon"></feather-icon>
            Request Pengembalian Stok
          </b-button>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- data -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(checkbox)>
              <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox v-if="item.stok > 0" v-model="checkedBarangs" :value="item"></b-form-checkbox>
              <feather-icon icon="SlashIcon" class="text-danger" v-else></feather-icon>
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(stok)="{ item }">
              {{ formatRupiah(item.stok) }}
            </template>
            <!-- <template #cell(satuan)="{ item }">
              {{ item.satuan ? item.satuan.satuan : "-" }}
            </template> -->
            <template #cell(stocks)="{ item }">
              {{ getTotalStock(item.stocks) }}
            </template>
            <template #cell(jumlah_retur)="{ item }">
              <b-form-input v-if="item.stok > 0" type="number" v-model="item.jumlah_retur"></b-form-input>
              <i class="text-danger" v-else>Stok kosong</i>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="checkedBarangs.length"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

      <retur-modal @submit="submit" :items="checkedBarangs"></retur-modal>
      <!-- :id_gudang="id_gudang" -->
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardText,
  BTable,
  BFormCheckbox,
  BTooltip,
  BPagination,
} from "bootstrap-vue";
import ReturModal from "./Modal.vue";
export default {
  components: {
    BOverlay,
    BCard,
    ReturModal,
    BInputGroupAppend,
    BFormCheckbox,
    BTable,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.checkedBarangs = [];
        this.items.map((barang) => {
          this.checkedBarangs.push(barang);
        });
        
      } else {
        this.checkedBarangs = [];
      }
    },
    checkedBarangs(items) {
      if (items.length > 0) {
        this.fields = [
          { key: "checkbox", label: "No" },
          { key: "nama", label: "Nama Barang" },
          { key: "satuan", label: "Satuan" },
          { key: "stok", label: "Stok" },
          { key: "jumlah_retur", label: "Stok Pengembalian" }
        ]
      } else {
        this.fields = [
          { key: "checkbox", label: "No" },
          { key: "no", label: "No" },
          { key: "kode", label: "kode" },
          { key: "kategori", label: "kategori" },
          { key: "nama", label: "Nama Barang" },
          { key: "satuan", label: "Satuan" },
          { key: "stok", label: "Stok" },
        ];
      }
    },
  },
  data: () => ({
    loading: false,
    allChecked: false,
    checkedBarangs: [],
    items: [],
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    fields: [],
    swalOptions: {
      title: "Request Pengembalian Stok Mobil?",
      text: `Request Pengembalian akan dikirim ke Gudang Penyimpanan`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-outline-success",
        cancelButton: "btn btn-danger ml-1",
      },
      buttonsStyling: false,
    },
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    salesId() {
      return this.$store.getters['gudang/findVehicle'](this.$route.params.id)
    }
  },
  methods: {
    async submit(payload) {
      const { pengembalian: pengembalianPayload, rincian } = payload;
      try {
        this.loading = true;
        const pengembalian = await this.$store.dispatch("pengembalianbarang/save", [
          pengembalianPayload,
        ]);

        // set payload for rincian pengembalian
        const payloads = [];
        rincian.map((payloadRincian) => {
          payloadRincian.id_pengembalian = pengembalian.id;
          payloads.push(payloadRincian);
        });

        // set payload for create rincian request pengembalian
        await this.$store.dispatch("pengembalianbarang/saveRincian", payloads);
        this.loading = false;
        this.checkedBarangs = []
        this.$emit("submitRetur", pengembalian);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    returnBarang() {
      this.$swal(this.swalOptions).then((res) => {
        if (res.value) {
          this.$bvModal.show("retur-modal");
        }
      });
    },
    getTotalStock(stocks) {
      if (!stocks || stocks.length < 1) {
        return 0;
      }
      return stocks.reduce((total, item) => (total += item.jumlah), 0);
    },
    async getData() {
      const id_gudang = this.isSales ? this.myGudang.id : this.salesId.id
      await this.$store
        .dispatch("gudang/getDataStokMobil", {
          order: "desc",
          id_gudang,
        })
        .then(() => {
          this.items = this.$store.state.gudang.datas;
          this.totalRows = this.items.length;
        });
    },
    async getBarang() {
      this.barangs = await this.$store.dispatch("barang/getDataV2");
      const barangIds = this.barangs.map((barang) => barang.id);
      this.getStockBarang(barangIds);
    },
    async getStockBarang(barangIds) {
      const params = {
        // barang_id: barangIds.join(','),
        // gudang_id: this.$route.params.id,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("penyimpanan/getData", params);
      this.barangs.map((barang) => {
        barang.stocks = [];
        const findStocks = stocks.filter(
          (stock) => stock.barang && stock.barang.id == barang.id
        );

        if (findStocks && findStocks.length > 0) {
          findStocks.forEach((stock) => {
            barang.stocks.push({
              id: stock.id,
              jumlah: stock.jumlah,
              gudang: stock.gudang,
              blok: stock.blok,
              rak: stock.rak,
              laci: stock.laci,
            });
          });
        }

        barang.jumlah_retur =
          barang.stocks.length > 0 ? this.getTotalStock(barang.stocks) : 0;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDataGudang1() {
      await this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    setFields() {
      if(this.isSales && this.myGudang) {
        this.fields = [
          { key: "checkbox", label: "No" },
          { key: "no", label: "No" },
          { key: "kode", label: "kode" },
          { key: "kategori", label: "kategori" },
          { key: "nama", label: "Nama Barang" },
          { key: "satuan", label: "Satuan" },
          { key: "stok", label: "Stok" }
        ]
      }
      else {
        this.fields = [
          { key: "no", label: "No" },
          { key: "kode", label: "kode" },
          { key: "kategori", label: "kategori" },
          { key: "nama", label: "Nama Barang" },
          { key: "satuan", label: "Satuan" },
          { key: "stok", label: "Stok" },
        ]
      }
    }
  },
  created() {
    this.setFields()
    // this.getBarang();
    this.getData();
    // this.getDataGudang1();
  },
};
</script>
