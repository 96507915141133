<template>
    <b-overlay :show="loading">
        <b-card
        >
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
                <b-table
                    striped
                    small
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="returs"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    
                >
                    <template #cell(no)="{index}">
                        {{ ++index }}
                    </template>
                    <template #cell(no_kwitansi)="{item}">
                        <span v-if="item.no_kwitansi">{{ item.no_kwitansi }}</span>
                        <i class="text-danger" v-else>Data Penjualan tidak ditemukan</i>
                    </template>
                    <template #cell(barang)="{item}">
                        <span v-if="item.barang">{{ item.barang }}</span>
                        <i class="text-danger" v-else>Barang tidak ditemukan</i>
                    </template>
                    <template #cell(tanggal)="{item}">
                        {{ humanDate(item.tanggal) }}
                    </template>
                    <template #cell(satuan)="{item}">
                        <span v-if="item.satuan">{{ item.satuan }}</span>
                        <i class="text-danger" v-else>Satuan tidak ditemukan</i>
                    </template>
                </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
    </b-overlay>
  </template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BCard,
    BFormCheckbox,
  } from "bootstrap-vue";
  
  export default {
    components: {
      ToastificationContent,
      VBTooltip,
      BCard,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BAvatar,
      BBadge,
      BOverlay,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox ,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        currentItem: {
          id_penjualan: null,
          id_konsumen: null,
          id_sales: null,
          nama_konsumen: null,
          tanggal: null,
          id_gudang_m: null,
          keterangan: null,
          rincian: []
        },
        checkedReturs: [],
        allChecked: false,
        id:null,
        blok_id:[],
        gudang_id :[],
        palet_id:[],
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        fields: [
            {
                key: 'no',
                label: 'No'
            },
            { key: "no_kwitansi", label: "Nota Penjualan", sortable: true },
            { key: "barang", label: "Barang", sortable: true },
            { key: "tanggal", label: "Tanggal Retur", sortable: true },
            { key: "jumlah", label: "Jumlah Retur", sortable: true },
            { key: "satuan", label: "Satuan Barang", sortable: true }
        ],
        returs: [],
        loading: false
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    created() {
      // Set the initial number of items
      this.getReturData()
    },
    methods: {
        async getReturData() {
            const params = {order: 'desc'}
            if(this.isSales) {
                params.id_sales = this.user.karyawan.id
            }
            else {
                params.id_sales = this.$store.state.kendaraan.currentSalesId
            }

            if(!this.isSales && this.myGudang) {
              params.gudang_id = this.myGudang.id
            }
            this.loading = true
            let returs = await this.$store.dispatch('retur-konsumen/getData', params)
            const returBarangs = []

            // returs = returs.filter(item => item.gudang_id == null)
                
            returs.map(retur => {
                if(retur.rincian.length > 0) {
                    retur.rincian.map(rincian => {
                        const payload = {
                            no_kwitansi: retur.penjualan ? retur.penjualan.no_kwitansi : null,
                            barang: rincian.barang ? rincian.barang.nama : null,
                            tanggal: retur.tanggal,
                            jumlah: rincian.qty,
                            satuan: rincian.barang && rincian.barang.satuan ? rincian.barang.satuan.satuan : null
                        }
    
                        returBarangs.push(payload)
                    })
                }
            })
            this.returs = returBarangs
            this.loading = false
            this.totalRows = returBarangs.length
            
        },
      add() {
        this.id = null
        this.currentItem = {
          id_penjualan: null,
          id_konsumen: null,
          id_sales: null,
          nama_konsumen: null,
          tanggal: this.getCurrentDate(),
          id_gudang_m: null,
          keterangan: null,
          rincian: []
        }
        this.$bvModal.show('retur-konsumen-modal')
      },
      edit(item) {
        this.id = item.id
        const currentItem = Object.assign({}, item)
        currentItem.nama_konsumen = currentItem.konsumen ? currentItem.konsumen.nama_toko : ''
        currentItem.id_penjualan = {
          value: currentItem.penjualan.id,
          text: `${currentItem.penjualan.no_kwitansi} - ${currentItem.konsumen ? currentItem.konsumen.nama_toko : ''}`,
          nama_konsumen: currentItem.konsumen ? currentItem.konsumen.nama_toko : '-',
          id_konsumen: currentItem.konsumen  ? currentItem.konsumen.id : null,
          rincian: []
        }
        currentItem.id_konsumen = currentItem.konsumen.id
        currentItem.id_sales = currentItem.sales.id
        currentItem.id_gudang_m = currentItem.sales.id
        this.currentItem = currentItem
        this.$bvModal.show('retur-konsumen-modal')
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data Retur ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-outline-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('retur-konsumen/save', [item])
            .then(() => {
              this.getReturData()
              this.displaySuccess({
                message: 'Berhasil'
              })
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        delete this.form.id
        this.form.palet_id = null
        this.form.blok_id = null
        this.form.gudang_id = null
        this.form.rak = null
      },
      async submit(payload) {
        if(this.id) {
            payload.id = this.id
        }

        try {
            this.loading = true
            // create retur
            const retur = await this.$store.dispatch('retur-konsumen/save', [payload])

            // create retur barang if rincian baran penjualan exist
            if(payload.rincian.length > 0) {
                const payloadsRincian = []
                payload.rincian.map(rincian => {
                    if(rincian.barang) {
                        payloadsRincian.push({
                            id_retur_konsumen: retur.id,
                            id_barang: rincian.barang.id,
                            qty: rincian.qty,
                            harga_jual: rincian.harga_jual
                        })
                    }
                })
                await this.$store.dispatch('retur-konsumen-rincian/save', payloadsRincian)
            }
            this.loading = false

            this.displaySuccess({
                message: 'Retur Konsumen berhasil ditambahkan'
            })

            // if edit action just reload data
            this.$bvModal.hide('retur-konsumen-modal')
            await this.getReturData()
            this.id = null
            setTimeout(() => {
                this.$router.push(`/retur-konsumen/detail/${retur.id}`)
            }, 1000)
        }
        catch(e) {
            this.loading = false
            this.displayError(e)
            return false
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    },
  };
  </script>
  